<template>
  <div>
    <el-row :gutter="10" type="flex" justify="center" style="margin-top:0px">
      <el-col>
        <div class="text-left">
          <el-button @click="handleCreate" type="text" icon="el-icon-circle-plus-outline">Добавить</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20" type="flex" justify="center" style="margin-top:0px">
      <el-col :span="30">
        <el-table v-loading="loading"
                  :height="tableHeight"
                  :data="gridItems"
                  style="width: 100%">
          <el-table-column label="" width="50">
            <template slot-scope="scope">
              <el-button type="text" icon="el-icon-edit"
                         @click="handleEdit(scope.row)"></el-button>
            </template>
          </el-table-column>
          <el-table-column prop="adGroup"
                           label="Группа AD" />
          <el-table-column label="Роли">
            <template slot-scope="scope">
              <span>{{ scope.row.roleGroupNames }}</span>
            </template>
          </el-table-column>
          <el-table-column label="" width="50">
            <template slot-scope="scope">
              <el-button type="text" icon="el-icon-delete"
                         @click="handleDelete(scope.row)"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-dialog :visible.sync="modalVisible" title="Группа Active Directory" width="35%" :close-on-click-modal="false">
      <el-form :rules="rules" ref="form" :model="modalModel" >
        <el-form-item prop="adGroup" label="Группа AD:">
          <el-input v-model="modalModel.adGroup" :disabled="!modalModel.isNew" required></el-input>
        </el-form-item>
        <el-form-item prop="roleGroupIds" label="Роли:">
          <el-select v-model="modalModel.roleGroupIds" multiple filterable size="medium" style="width:100%;" required>
            <el-option v-for="roleGroup in roleGroups"
                       :key="roleGroup.id"
                       :label="roleGroup.name"
                       :value="roleGroup.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-row :gutter="20" type="flex" justify="center">
        <el-col :span="24">
          <div  class="text-right">
            <el-button @click="handleSave">Сохранить</el-button>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
  import tableResize from '@/mixins/tableResize.js';
  import identityApi from '@/api/identity';
  import accessRightsApi from '@/api/administration/accessRights';
  import validationRules from '@/mixins/validationRules';

  export default {
    name: 'ADGroups',
    mixins: [tableResize, validationRules],
    data() {
      return {
        loading: false,
        gridItems: [],
        roleGroups: [],
        tableSizeConstant: 50,
        modalVisible: false,
        modalCreate: false,
        modalModel: {
          adGroup: '',
          roleGroupIds: [],
          isNew: false
        },
        rules: {
          adGroup: [
            { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
            { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
          ],
          roleGroupIds: [
            {
              type: 'array', required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'
            },
            { type: 'array', validator: this.arrayOneItemRequired, trigger: 'blur' }
          ]
        },
      };
    },
    async created() {
      this.loading = true;
      await this.getData();
      this.tableHeight = this.getTableHeight();
      this.loading = false;
    },
    methods: {
      async getData() {
        const response = await accessRightsApi.getActiveDirectoryGroups();
        this.gridItems = response.data.items;

        const roleGroupsResponse = await identityApi.getRoleGroups();
        this.roleGroups = roleGroupsResponse.data.items.filter((t) => t.code !== 'ExternalUser');
      },
      handleCreate() {
        this.modalCreate = true;
        this.modalModel.adGroup = '';
        this.modalModel.roleGroupIds = [];
        this.modalModel.isNew = true;
        this.modalVisible = true;
      },
      handleEdit(item) {
        this.modalCreate = false;
        this.modalModel.adGroup = item.adGroup;
        this.modalModel.roleGroupIds = item.roleGroupIds;
        this.modalModel.isNew = false;
        this.modalVisible = true;
      },
      async handleSave() {
        const valid = await this.$refs.form.validate();
        if (!valid) return;

        await accessRightsApi.saveActiveDirectoryGroup(this.modalModel, this.modalCreate);
        this.modalVisible = false;
        this.getData();
      },
      handleDelete(item) {
        this.$confirm(`Группа AD ${item.adGroup} будет удалена. Продолжить?`, 'Подтверждение', {
          confirmButtonText: 'Да',
          cancelButtonText: 'Отмена',
          type: 'warning',
        }).then(async () => {
          await accessRightsApi.deleteActiveDirectoryGroup(item.adGroup);
          this.getData();
        }).catch(() => { });
      },
    },
  };
</script>
