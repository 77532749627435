import axios from 'axios';

export default {
  get() {
    return axios.get('/api/AccessRights/Get');
  },

  save(accessRightsDto) {
    return axios.post('/api/AccessRights/Save', { dto: accessRightsDto });
  },

  getActiveDirectoryGroups() {
    return axios.get('/api/AccessRights/GetActiveDirectoryGroups');
  },

  saveActiveDirectoryGroup(adGroupDto, create) {
    // console.log('saveActiveDirectoryGroup', adGroupDto);
    return axios.post('/api/AccessRights/SaveActiveDirectoryGroup', { dto: adGroupDto, create });
  },

  deleteActiveDirectoryGroup(adGroup) {
    return axios.delete('/api/AccessRights/DeleteActiveDirectoryGroup', { params: { adGroup } });
  }
};  
